<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo"><img src="assets/img/logo_new_white.png" alt="logo"></a>
                    <p style="margin-top:-10px">
                        Una scuola di formazione e qualificazione professionale di eccellenza, che offre specializzazioni in ogni campo, con docenti altamente qualificati, stage e opportunità di lavoro concrete grazie al contatto diretto con le aziende.
                    </p>
                    <ul class="social-link">
                        <li><a href="https://www.facebook.com/adapaccademia/" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="https://www.instagram.com/adap.academy/" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="https://www.youtube.com/channel/UCfvu7GCQPuAkg1dkTIC9D1Q" class="d-block" target="_blank"><i class='bx bxl-youtube'></i></a></li>

                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Link</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/elenco-corsi">Elenco Corsi</a></li>
                        <li><a routerLink="/validita-attestati">Validità Attestato</a></li>
                        <li><a routerLink="/contatti">Contatti</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Login</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/">Discenti</a></li>
                        <li><a href="https://discentya.adap-reggiocalabria.it/login.php" target="_blank">Docenti</a></li>
                        <li><a href="https://discentya.adap-reggiocalabria.it/login.php" target="_blank">Partners</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Dove Siamo</h3>
                    <ul class="footer-contact-info">
                        <li><i class='bx bx-map'></i>Via Mattia Preti, 1d<br>Reggio Calabria - Italia</li>
                        <li><i class='bx bx-phone-call'></i><a href="tel:800729991">Telefono: 800-729991</a></li>
                        <li><i class='bx bx-envelope'></i><a href="mailto:fad@adapformazione.it">fad@adapformazione.it</a></li>
                        <li><i class='bx bxs-inbox'></i><a href="tel:+557854578964">Lunedì-Venerdì: 09:00 ÷ 20:00<br>Sabato: 08:30 ÷ 13:30</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="footer-bottom-area">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-8">
                    <p><i class='bx bx-copyright'></i>2023 ADAP Formazione- realizzato da <a target="_blank" href="https://www.discentya.it/">Discentya</a>, piattaforma per la formazione online</p>
                </div>
                <div class="col-lg-2 col-md-2">
                    <ul>
                        <li><a target="_blank" href="https://www.adapformazione.it/privacypolicy/">Privacy Policy</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</footer>
